/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import o from "../Color.js";
import { JSONSupport as e } from "../core/JSONSupport.js";
import { clone as t } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as l } from "../core/accessorSupport/decorators/enumeration.js";
import { reader as p } from "../core/accessorSupport/decorators/reader.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { writer as c } from "../core/accessorSupport/decorators/writer.js";
import { lineMarkerStyles as a } from "./support/lineMarkers.js";
var n;
let m = n = class extends e {
  constructor(r) {
    super(r), this.placement = "begin-end", this.type = "line-marker", this.style = "arrow";
  }
  writeStyle(r, o, e, t) {
    o[e] = "web-map" === t?.origin ? "arrow" : r;
  }
  set color(r) {
    this._set("color", r);
  }
  readColor(r) {
    return null != r?.[0] ? [r[0], r[1], r[2], r[3] / 255] : r;
  }
  writeColor(r, o, e, t) {
    "web-map" === t?.origin || (o[e] = r);
  }
  clone() {
    return new n({
      color: t(this.color),
      placement: this.placement,
      style: this.style
    });
  }
  hash() {
    return `${this.placement}.${this.color?.hash()}.${this.style}`;
  }
};
r([s({
  type: ["begin", "end", "begin-end"],
  json: {
    write: !0
  }
})], m.prototype, "placement", void 0), r([l({
  "line-marker": "line-marker"
}, {
  readOnly: !0
}), s({
  json: {
    origins: {
      "web-map": {
        write: !1
      }
    }
  }
})], m.prototype, "type", void 0), r([s({
  type: a
})], m.prototype, "style", void 0), r([c("style")], m.prototype, "writeStyle", null), r([s({
  type: o,
  value: null,
  json: {
    write: {
      allowNull: !0
    }
  }
})], m.prototype, "color", null), r([p("color")], m.prototype, "readColor", null), r([c("color")], m.prototype, "writeColor", null), m = n = r([i("esri.symbols.LineSymbolMarker")], m);
const u = m;
export { u as default };