/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { toPt as r } from "../core/screenUtils.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as t } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as e } from "../core/accessorSupport/decorators/subclass.js";
import i from "./Symbol.js";
let p = class extends i {
  constructor(o) {
    super(o), this.type = "simple-line", this.width = .75;
  }
  hash() {
    return `${this.type}.${this.width}`;
  }
};
o([t({
  esriSLS: "simple-line"
}, {
  readOnly: !0
})], p.prototype, "type", void 0), o([s({
  type: Number,
  cast: r,
  json: {
    write: !0
  }
})], p.prototype, "width", void 0), p = o([e("esri.symbols.LineSymbol")], p);
const c = p;
export { c as default };