/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { toPt as e } from "../core/screenUtils.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import { ensureNumber as s } from "../core/accessorSupport/ensureType.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import p from "./Symbol.js";
let i = class extends p {
  constructor(t) {
    super(t), this.angle = 0, this.type = null, this.xoffset = 0, this.yoffset = 0, this.size = 9;
  }
  hash() {
    return `${this.type}.${this.angle}.${this.size}.${this.xoffset}.${this.yoffset}`;
  }
};
t([o({
  type: Number,
  json: {
    read: t => t && -1 * t,
    write: (t, e) => e.angle = t && -1 * t
  }
})], i.prototype, "angle", void 0), t([o({
  type: ["simple-marker", "picture-marker"],
  readOnly: !0
})], i.prototype, "type", void 0), t([o({
  type: Number,
  cast: e,
  json: {
    write: !0
  }
})], i.prototype, "xoffset", void 0), t([o({
  type: Number,
  cast: e,
  json: {
    write: !0
  }
})], i.prototype, "yoffset", void 0), t([o({
  cast: t => "auto" === t ? t : s(e(t)),
  json: {
    write: !0
  }
})], i.prototype, "size", void 0), i = t([r("esri.symbols.MarkerSymbol")], i);
const a = i;
export { a as default };