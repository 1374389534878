/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { JSONMap as r } from "../core/jsonMap.js";
import { clone as t } from "../core/lang.js";
import { toPt as e } from "../core/screenUtils.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as i } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as n } from "../core/accessorSupport/decorators/subclass.js";
import l from "./LineSymbol.js";
import a from "./LineSymbolMarker.js";
var h;
const p = new r({
  esriSLSSolid: "solid",
  esriSLSDash: "dash",
  esriSLSDot: "dot",
  esriSLSDashDot: "dash-dot",
  esriSLSDashDotDot: "long-dash-dot-dot",
  esriSLSNull: "none",
  esriSLSShortDash: "short-dash",
  esriSLSShortDot: "short-dot",
  esriSLSShortDashDot: "short-dash-dot",
  esriSLSShortDashDotDot: "short-dash-dot-dot",
  esriSLSLongDash: "long-dash",
  esriSLSLongDashDot: "long-dash-dot"
});
let m = h = class extends l {
  constructor(...o) {
    super(...o), this.type = "simple-line", this.style = "solid", this.cap = "round", this.join = "round", this.marker = null, this.miterLimit = 2;
  }
  normalizeCtorArgs(o, r, t, s, i, n) {
    if (o && "string" != typeof o) return o;
    const l = {};
    return null != o && (l.style = o), null != r && (l.color = r), null != t && (l.width = e(t)), null != s && (l.cap = s), null != i && (l.join = i), null != n && (l.miterLimit = e(n)), l;
  }
  clone() {
    return new h({
      color: t(this.color),
      style: this.style,
      width: this.width,
      cap: this.cap,
      join: this.join,
      miterLimit: this.miterLimit,
      marker: this.marker?.clone()
    });
  }
  hash() {
    return `${super.hash()}.${this.color?.hash()}.${this.style}.${this.cap}.${this.join}.${this.miterLimit}.${this.marker?.hash()}`;
  }
};
o([i({
  esriSLS: "simple-line"
}, {
  readOnly: !0
})], m.prototype, "type", void 0), o([s({
  type: p.apiValues,
  json: {
    read: p.read,
    write: p.write
  }
})], m.prototype, "style", void 0), o([s({
  type: ["butt", "round", "square"],
  json: {
    write: {
      overridePolicy: (o, r, t) => ({
        enabled: "round" !== o && null == t?.origin
      })
    }
  }
})], m.prototype, "cap", void 0), o([s({
  type: ["miter", "round", "bevel"],
  json: {
    write: {
      overridePolicy: (o, r, t) => ({
        enabled: "round" !== o && null == t?.origin
      })
    }
  }
})], m.prototype, "join", void 0), o([s({
  types: {
    key: "type",
    base: null,
    defaultKeyValue: "line-marker",
    typeMap: {
      "line-marker": a
    }
  },
  json: {
    write: !0,
    origins: {
      "web-scene": {
        write: !1
      }
    }
  }
})], m.prototype, "marker", void 0), o([s({
  type: Number,
  json: {
    read: !1,
    write: !1
  }
})], m.prototype, "miterLimit", void 0), m = h = o([n("esri.symbols.SimpleLineSymbol")], m);
const d = m;
export { d as default };