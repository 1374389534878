/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import e from "../Color.js";
import { JSONMap as r } from "../core/jsonMap.js";
import { clone as t } from "../core/lang.js";
import { toPt as s } from "../core/screenUtils.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as l } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as p } from "../core/accessorSupport/decorators/subclass.js";
import { writer as a } from "../core/accessorSupport/decorators/writer.js";
import n from "./MarkerSymbol.js";
import c from "./SimpleLineSymbol.js";
var h;
const m = new r({
  esriSMSCircle: "circle",
  esriSMSSquare: "square",
  esriSMSCross: "cross",
  esriSMSX: "x",
  esriSMSDiamond: "diamond",
  esriSMSTriangle: "triangle",
  esriSMSPath: "path"
});
let u = h = class extends n {
  constructor(...o) {
    super(...o), this.color = new e([255, 255, 255, .25]), this.type = "simple-marker", this.size = 12, this.style = "circle", this.outline = new c();
  }
  normalizeCtorArgs(o, e, r, t) {
    if (o && "string" != typeof o) return o;
    const i = {};
    return o && (i.style = o), null != e && (i.size = s(e)), r && (i.outline = r), t && (i.color = t), i;
  }
  writeColor(o, e) {
    o && "x" !== this.style && "cross" !== this.style && (e.color = o.toJSON()), null === o && (e.color = null);
  }
  set path(o) {
    this.style = "path", this._set("path", o);
  }
  clone() {
    return new h({
      angle: this.angle,
      color: t(this.color),
      outline: this.outline && this.outline.clone(),
      path: this.path,
      size: this.size,
      style: this.style,
      xoffset: this.xoffset,
      yoffset: this.yoffset
    });
  }
  hash() {
    return `${super.hash()}.${this.color && this.color.hash()}.${this.path}.${this.style}.${this.outline?.hash()}`;
  }
};
o([i()], u.prototype, "color", void 0), o([a("color")], u.prototype, "writeColor", null), o([l({
  esriSMS: "simple-marker"
}, {
  readOnly: !0
})], u.prototype, "type", void 0), o([i()], u.prototype, "size", void 0), o([i({
  type: m.apiValues,
  json: {
    read: m.read,
    write: m.write
  }
})], u.prototype, "style", void 0), o([i({
  type: String,
  json: {
    write: !0
  }
})], u.prototype, "path", null), o([i({
  types: {
    key: "type",
    base: null,
    defaultKeyValue: "simple-line",
    typeMap: {
      "simple-line": c
    }
  },
  json: {
    default: null,
    write: !0
  }
})], u.prototype, "outline", void 0), u = h = o([p("esri.symbols.SimpleMarkerSymbol")], u);
const y = u;
export { y as default };